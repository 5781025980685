import { useNotify } from "./methods";

export const Regex = {
    Phone: /^\+\d{6,15}$/,
    AlphaNumeric: /^[a-zA-Z0-9]+$/,
    AlphaNumericSpaces: /^[a-zA-Z0-9\s]+$/,
    Numeric: /^[0-9]+$/,
    Alpha: /^[a-zA-Z]+$/,
    SpacedNumber: /\B(?=(\d{3})+(?!\d))/g,
    AnySpecial: /[^a-zA-Z0-9\s]/
}

export const Defaults = {
    GuidEmpty: "00000000-0000-0000-0000-000000000000",
    DateFormat: "YYYY-MM-DD",
    DateFormatDTB: "YYYY-MM-DDTHH:mm:ss",
    DateFormatFull: "DD MMMM YYYY",
    TimeFormat: "HH:mm",
    StringEmpty: "",
    UrlPrefix: "https://",
    Space: " ",
    ClientUrl: process.env.REACT_APP_PRODUCTION === "true" ? "https://sodevaconsulting.com" : "http://localhost:3000",
}

export const useDefaults = () => {
    const notify = useNotify();

    return {
        CaseMissingParameters: {
            case: 260,
            onCase: () => { notify("error", "toast_api_missingparameters"); }
        },
        CaseError: {
            case: 400,
            onCase: () => { notify("error", "toast_api_error"); }
        },
        CaseUnauthorized: {
            case: 260,
            onCase: () => { notify("error", "toast_api_unauthorized"); }
        },
    };
};

export const Currency = {
    EuroAbbr: "EUR",
    BritishPoundsAbbr: "GBP"
}

export const Storage = {
    Language: "language",
}

export const ToastType = {
    Info: "info",
    Warn: "warn",
    Error: "error",
    Success: "success"
}

export const Color = {
    DarkOrange: "#d2861c",
    Orange: "#ffa200",
    Black: "#112233",
    White: "#f9f9f9",
    WhiteSeeThrough: "#f9f9f977",
    RedSeeThrough: "#ff000077",
    Red: "#e02424",
    Green: "#09ab0b",
    LightBlue: "#33a3f2",
    Purple: "#b50297",

}