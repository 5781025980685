import { Outlet } from "react-router";
import { useTranslation } from "react-i18next";
import HeaderSection from "../components/Header";
import FooterSection from "../components/Footer";
import { Button, Image } from "antd";
import PromoImg from "../assets/sprites/PromoImg.jpg"
import Activity1 from "../assets/sprites/Activity1.png"
import Activity2 from "../assets/sprites/Activity2.png"
import Activity3 from "../assets/sprites/Activity3.png"
import Maps from "../assets/sprites/Maps.png"

export default function HomePage() {
  const { t } = useTranslation();

  return (
    <div className="bg-[#9cbc66]">
      <HeaderSection />
      <div className="flex justify-around gap-5 m-10 text-custom-foreground-white">
        <div>
          <p className="font-semibold text-5xl">{t("home_title")}</p>
          <p className="py-5 max-w-[50vw]">{t("home_subtitle")}</p>
          <Button onClick={() => {
            const footer = document.getElementById("footer");
            if (footer) {
              footer.scrollIntoView({ behavior: "smooth" });
            }
          }} className="bg-[#9cbc66] text-custom-foreground-white py-5 font-semibold border-2">{t("home_cta_location")}</Button>
        </div>
        <div className="max-w-[25vw]">
          <Image className="shadow-lg rounded-md" src={PromoImg} preview={false} />
        </div>
      </div>
      <div className="bg-custom-background-white text-[#023530] p-5">
        <p className="font-semibold text-3xl text-center pt-10">{t("home_activities_title")}</p>
        <p className="text-center text-[#999999] mt-3">{t("home_activities_subtitle")}</p>
        <div className="flex justify-evenly gap-5 mt-16 pb-10">
          <div className="flex-1 flex flex-col text-center"><Image className="max-w-[200px] mx-auto shadow-md rounded-full" src={Activity1} preview={false} /><span className="mt-5">{t("home_activity_one_title")}</span></div>
          <div className="flex-1 flex flex-col text-center"><Image className="max-w-[200px] mx-auto shadow-md rounded-full" src={Activity2} preview={false} /><span className="mt-5">{t("home_activity_two_title")}</span></div>
          <div className="flex-1 flex flex-col text-center"><Image className="max-w-[200px] mx-auto shadow-md rounded-full" src={Activity3} preview={false} /><span className="mt-5">{t("home_activity_three_title")}</span></div>
        </div>
      </div>
      <div className="relative">
        <img className="w-full" alt="-" src={Maps} />
      </div>
      <FooterSection />
      <Outlet />
    </div>
  );
}