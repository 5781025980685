import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider } from "antd";

import "./i18n";
import "./assets/styling/base.css";
import "./assets/styling/output.css";
import "react-toastify/dist/ReactToastify.css";

import BasePage from "./pages/Base";
import HomePage from "./pages/Home";
import UnfoundPage from "./pages/404";
import { ToastContainer } from "react-toastify";
// import AtomReader from "./components/AtomReader";
import { Color } from "./backend/enums";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastContainer autoClose={6000} limit={4} theme="colored" position="top-center" pauseOnHover={true} newestOnTop={true} pauseOnFocusLoss={true} closeOnClick={true} />
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={{
        components: {
          Splitter: {
            splitBarSize: 4,
            splitBarDraggableSize: 50
          },
          Menu: {
            itemBg: "transparant",
            itemSelectedBg: Color.DarkOrange,
            itemSelectedColor: Color.Black,
            dangerItemSelectedBg: Color.RedSeeThrough,
            dangerItemSelectedColor: Color.White
          },
          Form: {
            itemMarginBottom: 8
          },
          Slider: {
            trackBg: "#1677ff",
            handleColor: "#1677ff",
            dotActiveBorderColor: "#1677ff",
          },
        },
        token: {
          colorTextPlaceholder: Color.WhiteSeeThrough,
          controlItemBgActiveHover: Color.WhiteSeeThrough,
          controlItemBgActive: Color.WhiteSeeThrough,
          colorSplit: Color.WhiteSeeThrough
        },
      }}>
        <BrowserRouter>
          <Routes>
            <Route element={<BasePage />}>
              <Route index element={<HomePage />} />
              <Route path="*" element={<UnfoundPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
        {/* <AtomReader /> */}
      </ConfigProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  </React.StrictMode >
);