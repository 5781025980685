import { Footer } from "antd/es/layout/layout";
import { useTranslation } from "react-i18next";

export default function FooterSection() {
  const { t } = useTranslation();

  return (
    <Footer id="footer" className="border-t bg-sonar-white bg-[#023530] text-[#f9f9f9]">
      <div>
        <p className="text-2xl">{t("footer_title")}</p>
        <p className="py-4">{t("footer_subtitle")}</p>

        <p><span className="font-semibold">{t("footer_contact_phone")}: </span><a className="underline" href="tel:+3215421655">+32(0)15/42 16 55</a></p>
        <p><span className="font-semibold">{t("footer_contact_email")}: </span><a className="underline" href="mailto:info@sodevaconsulting.com">info@sodevaconsulting.com</a></p>
        <p><span className="font-semibold">{t("footer_contact_address")}: </span><a className="underline" target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Zemstbaan+24,+2800+Mechelen">{t("footer_contact_address_actual")}</a></p>
        <p><span className="font-semibold">{t("footer_contact_linkedin")}: </span><a className="underline" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/sodeva-consulting-&-development">Sodeva Consulting & Development</a></p>

        <p className="text-[#999999] pt-4">© 2023 Sodeva Consulting & Development | All Rights Reserved</p>
      </div>
    </Footer>
  );
}
