import { useRef } from "react";
import { Outlet } from "react-router";

export default function BasePage() {
  const containerRef = useRef(null);

  const handleSelectText = () => {
    const range = document.createRange();
    range.selectNodeContents(containerRef.current);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  };

  return (
    <div id="base" className="relative bg-custom-background-white text-custom-foreground-black">
      <div id="i18n-missing" className="absolute z-50 mt-5 ml-5 text-sm w-1" ref={containerRef} onClick={handleSelectText}></div>
      <Outlet />
    </div>
  );
}