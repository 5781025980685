import { useNavigate } from "react-router";
import { useEffect } from "react";

export default function UnfoundPage() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/result?type=info&context=result_404", { replace: true });
  }, [navigate])

  return (
    <></>
  );
}