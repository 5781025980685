import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./assets/locales/en.json";
import nl from "./assets/locales/nl.json";
import fr from "./assets/locales/fr.json";
import { Storage } from "./backend/enums";

const missingKeys = new Set();

setInterval(() => {
    if (process.env.REACT_APP_DISPLAY_MISSING_KEYS === "true" && missingKeys && missingKeys.size > 0) {
        const targetElement = document.getElementById("i18n-missing");

        if (targetElement) {
            missingKeys.forEach(key => { if (!targetElement.innerHTML.includes(key)) { targetElement.innerHTML += `"${key}":"xxxxx",<br/>`; } });
        }

        missingKeys.clear();
    }
}, 1000);

i18n.use(initReactI18next).init({
    fallbackLng: "en",
    debug: false,
    resources: { en, nl, fr },
    lng: localStorage.getItem(Storage.Language) ? localStorage.getItem(Storage.Language) : "nl",
    interpolation: { escapeValue: false },
    saveMissing: true,
    saveMissingTo: "current",
    parseMissingKeyHandler: (key) => { missingKeys.add(key); }
});
export default i18n;