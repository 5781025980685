import { Badge, Image } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import NL from "../assets/sprites/nl.png";
import EN from "../assets/sprites/gb.png";
import FR from "../assets/sprites/fr.png";
import { Defaults, Storage } from "../backend/enums";
import { CheckmarkIcon } from "../assets/sprites/SvgIcons";

export default function LanguageSelector({ isBox }) {
    const options = [
        { src: NL, alt: "nl" },
        { src: EN, alt: "en" },
        { src: FR, alt: "fr" }
    ];

    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(localStorage.getItem(Storage.Language) ? options.find(x => x.alt === localStorage.getItem(Storage.Language)) : options[0]);
    const { i18n } = useTranslation();
    const dropdownRef = useRef(null);

    const handleSelect = (option) => {
        setIsOpen(false);
        setSelected(option);
        i18n.changeLanguage(option.alt);
        localStorage.setItem(Storage.Language, option.alt);
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => { document.removeEventListener("click", handleClickOutside); };
    }, []);

    return (
        <div className="absolute select-none z-50 right-6 top-[18px] inline-block" ref={dropdownRef}>
            <Image
                width={32}
                height={32}
                alt={selected.alt}
                preview={false}
                src={selected.src}
                className="cursor-pointer rounded-md w-8 h-8 border-2 border-custom-background-black hover:border-custom-foreground-white transition duration-150"
                onClick={() => setIsOpen((prev) => !prev)}
            />
            {isOpen && (
                <div className={`absolute right-0 flex flex-col space-y-2 ${isBox ? "mt-1" : "-mt-4"}`}>
                    {options.map((option, index) => (
                        option.src === selected.src ? <Badge key={index} offset={[-4, 4]} count={<CheckmarkIcon />}>
                            <Image
                                width={32}
                                height={32}
                                alt={option.alt}
                                preview={false}
                                src={option.src}
                                className={`${option.src === selected.src ? "cursor-default" : "hover:border-custom-foreground-white transition duration-150"} w-8 h-8 rounded-md cursor-pointer border-2 border-custom-background-black`}
                                onClick={() => handleSelect(option)}
                            />
                        </Badge>
                            : <Image
                                key={index}
                                width={32}
                                height={32}
                                alt={option.alt}
                                preview={false}
                                src={option.src}
                                className={`${option.src === selected.src ? Defaults.StringEmpty : "hover:border-custom-foreground-white transition duration-150"} w-8 h-8 rounded-md cursor-pointer border-2 border-custom-background-black`}
                                onClick={() => handleSelect(option)}
                            />
                    ))}
                </div>
            )}
        </div>
    );
};
