import { Header } from "antd/es/layout/layout";
import { Link } from "react-router";
import LOGONAME from "../assets/sprites/Logo.png";
import { Image, Button } from "antd";
import LanguageSelector from "../components/LanguageSelector";
import { useTranslation } from "react-i18next";

export default function HeaderSection() {
  const { t } = useTranslation();

  return (
    <>
      <Header className="sticky py-2 pr-16 pl-4 flex justify-between top-0 z-50 border-b backdrop-blur-sm shadow-sm bg-[#9cbc6699] duration-75 transition-colors p-0 h-auto">
        <Link className="flex focus-visible:outline-none p-4 bg-white rounded-lg" to={"/"}>
          <Image rootClassName="hidden sm:block my-auto mx-3" height={30} width={190} className="min-w-[200px] max-h-[200px]" src={LOGONAME} preview={false} />
        </Link>
        <div className="mr-2 text-custom-foreground-white">
          <Button type="text" onClick={() => {
            const footer = document.getElementById("footer");
            if (footer) {
              footer.scrollIntoView({ behavior: "smooth" });
            }
          }} className="font-semibold text-custom-foreground-white">{t("header_contact")}</Button>

        </div>
        <LanguageSelector />
      </Header>
    </>
  );
}
